<template>
    <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
        Memuatkan senarai murid
    </loading-spinner>
    <dependant-details v-else-if="currentDependant" :currentDependant="currentDependant" @update="updateDependant"
        @close="viewDependant" />
    <div v-else>
        <div class="pb-3 d-flex justify-content-between align-items-center">
            <div class="input-group">
                <span class="input-group-text bg-white">
                    <i class="fas fa-search" />
                </span>
                <input type="text" class="form-control border-start-0 ps-0 no-focus"
                    :class="{ 'border-end-0': search.length > 0 }" placeholder="Cari nama murid" v-model="search" />
                <button class="btn bg-white border border-light" type="button" v-if="search.length > 0"
                    @click="search = ''">
                    &times;
                </button>
            </div>
        </div>
        <div class="pb-3 d-flex justify-content-between align-items-center">
            <span class="badge bg-dark py-2 fw-normal">
                Jumlah Murid: {{ dependants.length }}
            </span>
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                        <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber"
                        :class="{ 'active': currentPage === pageNumber }">
                        <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
                    </li>
                    <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                        <a class="page-link" href="#" aria-label="Next" @click="nextPage">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="table-responsive rounded shadow-sm rounded border border-light position-relative">
            <table class="table lh-sm table-admin w-100">
                <thead>
                    <tr>
                        <th>Nama</th>
                        <th>Kelas</th>
                        <th>Sekolah</th>
                        <th class="text-center ps-0">Penilaian</th>
                        <th class="text-center ps-0">Sijil</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dependant in displayedData" :key="dependant._id" :ref="`dependant_${dependant._id}`">
                        <td class="text-nowrap text-truncate" style="font-weight: 500;">
                            <p :title="dependant.name" class="mb-0 clickable" @click="viewDependant(dependant._id, 'assessments')">{{ dependant.name
                            }}</p>
                        </td>
                        <td class="text-nowrap text-truncate" style="font-weight: 500;">
                            <p class="mb-0">{{ dependant.class ? dependant.class.name : '' }}</p>
                        </td>
                        <td class="text-nowrap text-truncate" style="font-weight: 500;">
                            <p :title="dependant.schoolName" class="mb-0">{{ dependant.schoolName }}</p>
                        </td>
                        <td class="text-center">
                            <span class="badge bg-light border text-dark fw-normal p-2 clickable"
                                @click="viewDependant(dependant._id, 'assessments')">
                                {{
                                    dependant.dependant_assessments ? dependant.dependant_assessments.length : 0
                                }}
                            </span>
                        </td>
                        <td class="text-center">
                            <span class="badge bg-light border text-dark fw-normal p-2 clickable"
                                @click="viewDependant(dependant._id, 'certificates')">
                                {{
                                    dependant.dependant_certificates
                                    ? dependant.dependant_certificates.length
                                    : 0
                                }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="computedDependants.length == 0">
                        <td colspan="5" class="text-center fst-italic text-muted">
                            Tiada murid dijumpai
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex flex-row-reverse">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                        <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber"
                        :class="{ 'active': currentPage === pageNumber }">
                        <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
                    </li>
                    <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                        <a class="page-link" href="#" aria-label="Next" @click="nextPage">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
  
<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import DependantDetails from "@/components/DependantDetails";

export default {
    components: { LoadingSpinner, DependantDetails },
    data() {
        return {
            isLoading: false,
            search: "",
            dependants: [],
            schools: [],
            currentDependant: null,
            currentPage: 1,
            itemsPerPage: 50
        };
    },
    watch: {
        "$route.params": {
            handler() {
                this.handleParamChange();
            },
            deep: true,
            immediate: false,
        },
    },
    computed: {
        computedDependants() {
            const search = this.search.toLowerCase();

            return this.dependants.filter((dependant) =>
                dependant.name.toLowerCase().includes(search)
            );
        },
        dependantAssessmentCount() {
            return (dependantClasses) => {
                return dependantClasses
                    // .map(
                    //   (cls) =>
                    //     cls.assessments.filter((ca) => ca.status == "Published").length
                    // )
                    .reduce((total, num) => total + num, 0);
            };
        },
        totalPages() {
            return Math.ceil(this.dependants.length / this.itemsPerPage);
        },
        displayedData() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            if (this.search != '') {
                return this.computedDependants
            } else {
                return this.dependants.slice(startIndex, endIndex);
            }
        }
    },
    methods: {
        updateDependant(e) {
            let dependant = this.dependants.find((d) => d._id == e._id);
            let dependantIndex = this.dependants.findIndex((d) => d._id == e._id);

            if (dependant) {
                this.$set(this.dependants, dependantIndex, e);

                this.handleParamChange();
            }
        },
        viewDependant(dependantId, view) {
            let route = {
                name: this.$route.name,
                params: {
                    id: dependantId,
                },
                query: {},
            };

            if (view) {
                route.query["view"] = view;
            }

            this.$router.push(route);
        },
        handleParamChange() {
            if (this.$route.params.id) {
                this.currentDependant = this.dependants.find(
                    (dependant) => dependant._id == this.$route.params.id
                );
            } else {
                this.currentDependant = null;
            }
        },
        goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.search = '';
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.search = '';
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.search = '';
            }
        },
        async getDependants() {
            return new Promise(async (resolve) => {
                try {
                    let data;
                    if (this.$store.state.user.role.name == 'Officer') {
                        ({ data } = await this.API.get(
                            `dependants?_limit=-1&status_in=Active&status_in=Inactive`
                        ));
                    } else if (this.$store.state.user.schoolAdmin == true) {
                        ({ data } = await this.API.get(
                            `dependants?_limit=-1&status_in=Active&status_in=Inactive&class.school=${this.$store.state.user.school}`
                        ));
                    } else {
                        ({ data } = await this.API.get(
                            `dependants?_limit=-1&status_in=Active&status_in=Inactive&class.school=${this.$store.state.user.school}`
                        ));
                    }

                    const schoolMap = new Map();

                    this.schools.forEach((school) => {
                        schoolMap.set(school._id, school.name);
                    });

                    data = data.map((student) => ({
                        ...student,
                        schoolName: student.class != null ? schoolMap.get(student.class.school) : null,
                    }));

                    this.dependants = data;

                    if (data.length > 0) {
                        if (data.some((d) => d.classes && d.classes.length > 0)) {
                            // await this.getClasses(
                            //   this.Helper.removeDuplicates(
                            //     data.map((d) => d.classes.map((c) => c._id)).flat()
                            //   )
                            // );
                        }

                        if (this.$store.state.user.isStudent) {
                            this.$route.params.id = data[0]._id;
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    resolve();
                }
            });
        },
        async getSchools() {
            this.isLoading = true;

            return new Promise(async (resolve) => {
                try {
                    const { data } = await this.API.get(
                        `schools?_limit=-1`
                    );

                    this.schools = data;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isLoading = false;

                    resolve();
                }
            });
        },
    },
    async mounted() {
        let waitUserAvailable = async () => {
            this.isLoading = true;

            if (this.$store.state.user) {
                await this.getSchools();
                await this.getDependants();

                this.isLoading = false;

                this.handleParamChange();
            } else {
                setTimeout(() => {
                    waitUserAvailable();
                }, 1000);
            }
        };

        waitUserAvailable();
    },
};
</script>